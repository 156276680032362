
const server = {
//baseURL:'https://local.quattropy.com/emprendimiento/s1/public/',
    //baseURL:'https://emprendimientosvirtuales.com.py/s1_cerro/public/',
    //baseURL:'https://emprendimientosvirtuales.com.py/s1_guairena/public/',
    //baseURL:'https://votacandidatos.sospy.org/s1/public/',
    baseURL:'https://votaolimpia.sospy.org/s1/public/',
    urlProducto:''
}

export default server;