import {
  REGISTER_REPRESENTANTE_REQUEST,
  REGISTER_REPRESENTANTE_SUCCESS,
  REGISTER_REPRESENTANTE_FAIL,
} from "../../constants/ProjectType";
import clienteAxios from "../../config/axios";
import swal from "sweetalert";

const enviarVotaciones = (votos) => async (dispatch) => {
    dispatch({
      type: REGISTER_REPRESENTANTE_REQUEST,
      payload: {votos},
    });

    try {
      const { data } = await clienteAxios.post("/votapoliticos/votaciones/add",votos);
      dispatch({ type: REGISTER_REPRESENTANTE_SUCCESS, payload: data.data });

      if (data.status === "success") {
        swal({
          title: "¡Gracias!",
          text: "Tus votos se enviaron con éxito",
          icon: "success",
          closeOnClickOutside: false,
          closeOnEsc: false,
          allowOutsideClick: false,
          SuccessMode: true,
        }).then((willDelete) => {
          window.location.reload();
        });
        window.location.reload();
      } else {
        swal({
          title: "¡Ups!",
          text: data.message,
          icon: "",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      }
    } catch (error) {
      swal({
        title: "¡Ups!",
        text: error.message,
        icon: "",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
      dispatch({ type: REGISTER_REPRESENTANTE_FAIL, payload: error.message });
    }
  };
  

export { enviarVotaciones };
