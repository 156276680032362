import React from "react";
import { Link } from "react-router-dom";
import logohome from "../assets/imagenes/votaciones-olimpia/especificas/logo-home-3.png";
import logohome2 from "../assets/imagenes/votaciones-olimpia/especificas/logo-menu.png";
import iconmenu from "../assets/imagenes/votaciones-olimpia/generales/icon-menu.png"
import { useSelector } from "react-redux";
import "../App.css";

function IconLogo(props) {
  // const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  return (
    <React.Fragment>
  {userInfo ? (
              <Link to="/">
              <img
                src={logohome}
                className="icon-logo"
                alt="Emprendimientos virtuales"
              />
            </Link>
      ) : (
        <Link to="/">
        <img
          src={logohome2}
          className="icon-logo"
          alt="Emprendimientos virtuales"
        />
      </Link>
      )}
    </React.Fragment>
  );
}

export default IconLogo;
