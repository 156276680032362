import React, { useEffect, useState } from "react";
import "../politicos.css";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../action/especificos/PostulantesActions";
import { enviarVotaciones } from "../action/especificos/VotacionActions";
import Slider from "react-slick";
import notfound from "../assets/imagenes/votaciones-olimpia/generales/gracias.png";
import blanco from "../assets/imagenes/votaciones-olimpia/especificas/blanco.png";

import General from "../componentes/modals/General";
const arrayDetalles = [];
const arrayVotados = [];
function PoliticosVotacion() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const [load, setLoad] = useState(true);
  const [dataPostulantes, setDataPostulantes] = useState([]);
  const [arrayPostulantes, setArrayPostulantes] = useState([]);
  const [detallePostulantes, setDetallePostulantes] = useState([]);
  const elecciones = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const agregarPostulantes = (id) => {
    const event = document.getElementById("list_politico");
    var elemento = document.getElementById("competitor-" + id);
    elemento.className += " opacarImg";
    var imagen = elemento.getAttribute("data-imagen");
    var nombre = elemento.getAttribute("data-nombre");
    var apellido = elemento.getAttribute("data-apellido");
    var id = elemento.getAttribute("data-id");
    var elec_cargo = elemento.getAttribute("data-eleccion");
    var nombre_cargo = elemento.getAttribute("data-nombre-cargo");
    var id_cargo = elemento.getAttribute("data-idcargo");
    var color = elemento.getAttribute("data-color");
    var ideleccion = elemento.getAttribute("data-idelecion");
    var posicion_politico = elemento.getAttribute("data-orden");
    var nulos = elemento.getAttribute("data-val");
    var cantidad_votos = elemento.getAttribute("data-cant");

    var objPostulantes = {
      imagen_politico: imagen,
      nombre_politico: nombre,
      apellido_politico: apellido,
      id_politico: id,
      id_elec_cargo: elec_cargo,
      posicion_politico: posicion_politico,
    };

    var ban = 0;
    if (arrayDetalles.length > 0) {
      for (const key in arrayDetalles) {
        const element = arrayDetalles[key];
        if (element["id"] == elec_cargo) {
          ban++;
          element["detalle"].push(objPostulantes);
          break;
        }
      }
      if (ban == 0) {
        arrayDetalles.push({
          id: elec_cargo,
          nombre: nombre_cargo,
          color: color,
          detalle: [objPostulantes],
        });
      }
    } else {
      arrayDetalles.push({
        id: elec_cargo,
        nombre: nombre_cargo,
        color: color,
        detalle: [objPostulantes],
      });
    }
    //console.log(arrayDetalles)
    setDetallePostulantes(arrayDetalles);

    arrayVotados.push({
      imagen_politico: imagen,
      nombre_politico: nombre,
      apellido_politico: apellido,
      id_politico: id,
      id_elec_cargo: elec_cargo,
      id_eleccion: ideleccion,
      id_cargo: id_cargo,
      id_cliente: userInfo.id_cliente,
      posicion_politico: posicion_politico,
    });

    setArrayPostulantes(arrayVotados);
    if (nulos == 0) {
      const result = arrayPostulantes.filter(
        (obj) => obj.id_elec_cargo == elec_cargo
      );
      if (result.length == cantidad_votos) {
        siguiente(elec_cargo);
      }
    }
  };

  useEffect(() => {
    if (load) {
      const loadProducts = () => dispatch(fetchProducts());
      loadProducts();
      setLoad(false);
    }
  }, [elecciones, dispatch, load]);

  const siguiente = (id, bandera = 0) => {
    const result = arrayPostulantes.filter((obj) => obj.id_elec_cargo == id);
    console.log("res", result);
    console.log("array", arrayPostulantes);
    window.scrollTo(0, 0);

    if (result.length <= 0 && bandera == 0) {
      swal({
        title: "¡Ups !",
        text: "Seleccioná por lo menos un candidato",
        icon: "error",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
      return false;
    }
    var elemento1 = document.getElementById("content-elec-" + id);
    elemento1.style.display = "none";
    var elemento2 = document.getElementById("content-elec-" + id).nextSibling;
    //console.log("ele", elemento2)

    if (elemento2) {
      elemento2.style.display = "block";
    } else {
      document.getElementById("navigation").style.display = "none";
      document.getElementById("detalle-votos").style.display = "block";
      //console.log("nav",document.getElementById("navigation"))
    }

    //console.log("element2", elemento2);
  };

  const votoBlanco = (agregar, next) => {
    console.log("inicio", arrayPostulantes); //json original
    eliminarPorID(next);
    console.log("fin", arrayPostulantes); //json sin su elemento
    agregarPostulantes(agregar);
    siguiente(next, 1);
    window.scrollTo(0, 0);
  };
  function eliminarPorID(id) {
    console.log("true", arrayPostulantes);
    arrayPostulantes.forEach(function (currentValue, index, arr) {
      console.log(arrayPostulantes[index].id_elec_cargo + "=" + id);

      if (arrayPostulantes[index].id_elec_cargo == id) {
        console.log("true", index);
        //arrayPostulantes.splice(index-1, 1);
        delete arrayPostulantes[index];
      }
    });
    detallePostulantes.forEach(function (currentValue, index, arr) {
      //console.log(index)
      detallePostulantes[index].detalle.forEach(function (
        currentValue,
        i,
        arr
      ) {
        // console.log(detallePostulantes[index].id_elec_cargo+"="+id)

        if (detallePostulantes[index].detalle[i].id_elec_cargo == id) {
          console.log("true", index);
          //detallePostulantes.splice(index-1, 1);
          delete detallePostulantes[index].detalle[i];
        }
      });
    });
  }

  const history = useHistory();

  const goBack = () => {
    window.location.replace("/");
  };
  const aceptar = (e) => {
    e.preventDefault();
    swal({
      title: "cargando...",
    });
    dispatch(enviarVotaciones({ votos: arrayPostulantes }));
  };
  console.log("detalle", detallePostulantes);

  return (
    <React.Fragment>
      {!elecciones.items ? (
        <Slider />
      ) : elecciones.items.eleccion.length === 0 ? (
        <div className="container-notf2">
          <h1 className="h1 centrar">Olimpia 2023</h1>
          <img src={notfound} className="img-notfound" alt="Error" />

          <h1 className="h1-1">¡Muchas gracias por tu opinión!</h1>
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : (
        <div>
          <h1 className="h1 centrar">
            {elecciones.items.eleccion.nombre_eleccion}
          </h1>
          <p className="p text-center">
            {" "}
            {elecciones.items.eleccion.descripcion_eleccion}
          </p>

          <div>
            {elecciones.items.cargos.map((eleccion, index) => {
              //console.log("index", index);
              let display = "block";
              if (index > 0) {
                display = "none";
              }
              return (
                <div
                  key={eleccion.id_elec_cargo}
                  className="row mt-4"
                  id={`content-elec-${eleccion.id_elec_cargo}`}
                  style={{ display: display }}
                >
                  <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
                    <div
                      className="partido"
                      style={{
                        backgroundColor: `${eleccion.color_elec_cargo}`,
                      }}
                      id="list_politico"
                    >
                      <div className="cab-aviso">
                        <h2 className="centrar">{eleccion.nombre_cargo}</h2>
                      </div>
                      <h2 className="centrar text-white aviso">
                        Seleccionar hasta {eleccion.cant_votos_elec_cargo} opciones.
                        <General />
                      </h2>

                      <br />
                      <div className="row">
                        {eleccion.postulantes.map((postulante, index) => {
                          return (
                            <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-3">

                              <div
                                className="competitor"
                                id={`competitor-${postulante.id_elec_cargo}-${postulante.id_politico}`}
                                data-imagen={postulante.imagen_politico}
                                data-nombre={postulante.nombre_politico}
                                data-apellido={postulante.apellido_politico}
                                data-id={postulante.id_politico}
                                data-eleccion={postulante.id_elec_cargo}
                                key={postulante.id_politico}
                                data-nombre-cargo={eleccion.nombre_cargo}
                                data-idcargo={eleccion.id_cargo}
                                data-color={eleccion.color_elec_cargo}
                                data-idelecion={
                                  elecciones.items.eleccion.id_eleccion
                                }
                                data-orden={postulante.posicion_politico}
                                data-val={eleccion.votos_nulos_elec_cargo}
                                data-cant={eleccion.cant_votos_elec_cargo}
                              >
                             {/* <Link href="/"> <p className="nombre-politico">Curriculum</p></Link> */}
                                <Link
                                  data-competitor-id={postulante.id_politico}
                                  onClick={() => {
                                    agregarPostulantes(
                                      `${postulante.id_elec_cargo}-${postulante.id_politico}`
                                    );
                                  }}
                                >
                                  <div className="competitor-img">
                                    <img
                                      src={`${config_server.linkImagePolitico}/${postulante.imagen_politico}`}
                                      alt={`${postulante.apellido_politico} ${postulante.nombre_politico}`}
                                      className="img-fluid"
                                    />
                                  </div>
                                </Link>
                                <div className="competitor-btn text-center">
                                  <div className="numero-politico">
                                    {postulante.posicion_politico}
                                  </div>
                                  <p className="nombre-politico">
                                    {postulante.apellido_politico}{" "}
                                    {postulante.nombre_politico}
                                  </p>
                                  <Link
                                    data-competitor-id={postulante.id_politico}
                                    onClick={() => {
                                      agregarPostulantes(
                                        `${postulante.id_elec_cargo}-${postulante.id_politico}`
                                      );
                                    }}
                                  >
                                    <div className="centrar">
                                      <button className="btn btn-woman voteButton text-center">
                                        Votar
                                      </button>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {eleccion.votos_blancos_elec_cargo == 1 && (
                          <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-3">
                            <div
                              className="competitor"
                              id={`competitor-${eleccion.id_elec_cargo}-0`}
                              data-imagen="blanco.png"
                              data-nombre="Blanco"
                              data-apellido="Blanco"
                              data-id="0"
                              data-eleccion={eleccion.id_elec_cargo}
                              key="0"
                              data-nombre-cargo={eleccion.nombre_cargo}
                              data-idcargo={eleccion.id_cargo}
                              data-color={eleccion.color_elec_cargo}
                              data-idelecion={
                                elecciones.items.eleccion.id_eleccion
                              }
                            >
                              <Link
                                data-competitor-id="0"
                                onClick={() => {
                                  votoBlanco(
                                    `${eleccion.id_elec_cargo}-0`,
                                    `${eleccion.id_elec_cargo}`
                                  );
                                }}
                              >
                                <div className="competitor-img">
                                  <img
                                    src={`${config_server.linkImagePolitico}/blanco.png`}
                                    alt="blanco"
                                    className="img-fluid"
                                  />
                                </div>
                              </Link>
                              <div className="competitor-btn text-center">
                                <p className="nombre-politico">
                                  <br />
                                  <br />
                                </p>
                                <Link
                                  data-competitor-id="0"
                                  onClick={() => {
                                    votoBlanco(
                                      `${eleccion.id_elec_cargo}-0`,
                                      `${eleccion.id_elec_cargo}`
                                    );
                                  }}
                                >
                                  <div className="centrar">
                                    <button className="btn btn-woman voteButton text-center">
                                      Votar
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="centrar">
                      <button
                        id="button-siguiente"
                        className="button-red"
                        onClick={() => {
                          siguiente(`${eleccion.id_elec_cargo}`);
                        }}
                      >
                        Siguiente
                      </button>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              );
            })}
          </div>
          <div id="detalle-votos" style={{ display: "none" }}>
            <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
              <h1 className=" h1-1 text-center resumen-v">
                Resumen de tus opiniones:
              </h1>
              {detallePostulantes.map((detallePostu) => {
                return (
                  <div>
                    <div
                      className="partido"
                      style={{ backgroundColor: `${detallePostu.color}` }}
                    >
                      <div className="cab-aviso-2">
                        <h2 className="centrar"> {detallePostu.nombre}</h2>
                      </div>
                      <br />
                      <div className="row">
                        {detallePostu.detalle.map((postulante) => {
                          return (
                            <div className="col-lg-4 col-md-4 col-sm-4 col-6 mb-3">
                              <div
                                className="competitor"
                                id={`competitor-${postulante.id_politico}`}
                              >
                                <div className="competitor-img">
                                  <img
                                    src={`${config_server.linkImagePolitico}/${postulante.imagen_politico}`}
                                    alt={`${postulante.apellido_politico} ${postulante.nombre_politico}`}
                                    className="img-fluid"
                                  />
                                </div>

                                <div className="text-center">
                                  <p className="nombre-politico-detalle">
                                    {postulante.apellido_politico}{" "}
                                    {postulante.nombre_politico}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="centrar">
              <button
                className="button-red"
                onClick={aceptar}
                className="button-red"
              >
                Confirmar
              </button>
              <button className="button-red-2" onClick={goBack}>
                Reiniciar
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default PoliticosVotacion;
