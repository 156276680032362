import React from "react";
import "../App.css";
import { useSelector } from "react-redux";
import PoliticosVotacion from "../politicos/PoliticosVotacion";
import Yavoto from "./Yavoto";

function Home2() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  return (
    <React.Fragment>

                    <PoliticosVotacion/>
 



    </React.Fragment>
  );
}

export default Home2;
