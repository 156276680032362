import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Sidebar1 from "./Sidebar1";
// import Tada from 'react-reveal/Tada';
import BotonUser2 from "../userBotones/BotonUser2";
import logohome from "../assets/imagenes/votaciones-olimpia/especificas/logo-home-3.png";
import logohome2 from "../assets/imagenes/votaciones-olimpia/especificas/logo-menu.png";
import iconmenu from "../assets/imagenes/votaciones-olimpia/generales/icon-menu.png"
import BotonUser from "../userBotones/BotonUser";
import IconLogo from "../userBotones/IconLogo";



class Navigation extends React.Component {

  render() {
    const openMenu = () => {
      document.querySelector(".sidebar").classList.add("open");
    };



    return (
      <div className="navigation" id="navigation">
        <nav className="nav1" >
          <div className="contenedor-nav" >

            <IconLogo/>
              {/* </Tada> */}
              <div className="menu33">
              <BotonUser2/>
              <BotonUser/>
           
              </div>
          </div>
        </nav>

        <div className="sidebar">
          
          <Sidebar />
        </div>
        <nav className="nav2">
          <div className="contenedor-nav2" >


            <Sidebar1/>

 
          </div>
        </nav>
      </div>
    );
  }
}
export default Navigation;
