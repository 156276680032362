import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  FETCH_PRODUCTS
} from "../../constants/ProductType";
import swal from 'sweetalert';
import clienteAxios from '../../config/axios';


export const fetchProducts = () => async (dispatch,getState) => {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState(); 
    let id= "0";

    if (!userInfo){
       id ="0"
      
    } else{
      id= userInfo.id_cliente
    }
    try {
      const { data } = await clienteAxios.get(`/votapoliticos/verPostulaciones/${id}`);
      dispatch({ type: FETCH_PRODUCTS, payload: data.data });
      //localStorage.setItem("id_eleccion", JSON.stringify(data.data.id_eleccion));
      
    } catch (error) {
      dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message }); 
        swal({
          title: "¡Ups !",
          text: error.message,
          icon: "error",
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
           window.location.replace('/');
           
          }
        });
    }
  };
  


export const detailsProduct = (productId) => async (dispatch) => {

    try {
      dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productId });
      const data = await clienteAxios.get(`/encounters/listSelectById/${productId}`);
      //console.log("details", data);
      dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({ type: PRODUCT_DETAILS_FAIL, payload: error.message });
      swal({
        title: "¡Ups !",
        text: error.message,
        icon: "error",
        dangerMode: true,
      })
      .then(willDelete => {
        if (willDelete) {
         window.location.replace('/');
         
        }
      });
    }
   
  };